import axios from "axios"
import qs from 'qs'

import store from "../store/index"
// const baseURL = process.env.VUE_APP_BASE_API
// console.log(store.state.UserToken)
// console.log(process.env);
export function request(config) {


	// return new Promise((resolve,reject)=>{
	//  1 创建
	// http://192.168.0.90:801
	const instance = axios.create({
		baseURL: process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_URL,
		timeout: 5000,

	})
	// 2 请求前拦截器
	// 请求前拦截 两个参数  成功、失败 函数
	instance.interceptors.request.use(config => {
		config.headers["Authorization"] = store.state.UserToken
		if (config.method === 'post') {
			if (config.url == '/web/common/upload') {
				if (store.state.UserToken) {
					// config.headers["token"]= store.state.UserToken

				}
			} else {
				// config.headers["token"]= store.state.UserToken
				config.data = qs.stringify(config.data, {
					indices: false
				})
			}
			config.headers["content-type"] = 'application/x-www-form-urlencoded'
			// config.headers["content-type"]= 'multipart/form-data'
			// config.headers["content-type"]= 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
			// 
			// config.data = qs.stringify(config.data)

			// console.log(config)
		}
		return config
	}, err => {
		// console.log(err,"111111111111");
		return err
	})
	// 3 请求后拦截器
	// 请求后拦截  两个参数  成功、失败 函数
	instance.interceptors.response.use(res => {
		
		// console.log(res,"////////////")
		return res.data
	}, (err) => {
		console.log(err,"******************");
		// return err
	})


	// 3 发送
	return instance(config)
	// return instance(config)
	// .then(res=>{
	//     resolve(res)
	// })
	// .catch(err=>{
	//     reject(err)
	// })
	// })
}








